import React from "react";

import InfoContenido from "./datos/datosContenido";
import InfoCercania from "./datos/datosCercania";

import Hero from "../../common/hero";
import Contenido from "./layout/contenido";
import Calidad from "./layout/calidad";

const Nosotros = ({ children }) => (
  <>
    <Hero texto="Calidad 5 estrellas garantizada" />
    <Calidad />
    <Hero texto="¿Qué te ofrecemos?" />
    <Contenido data={InfoContenido} />
    <Hero texto="Y siempre, cerca de tí" />
    <Contenido data={InfoCercania} />
    <Hero texto="Gestión Integral. Calidad Asegurada." />
  </>
);

export default Nosotros;
