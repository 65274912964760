const info = [
  {
    key: 0,
    text:
      "Coordinador en el cole. Apoyo y atención personalizada a alumnos y familias."
  },
  {
    key: 1,
    text: "Atención permanente a la AMPA de su técnico. Calendario de visitas."
  },
  {
    key: 2,
    text:
      "Servicio telefónico de atención a padres (Departamento pedagógico y administrativo siempre a tu disposición."
  }
];

export default info;
