import React from "react";

import "./style.scss";
import Helmet from "./common/helmet";
import NavBar from "./common/navbar";
import SubMenu from "./common/submenu";
import Nosotros from "./layouts/nosotros/nosotros";
import Footer from "./common/footer";

const Layout = ({ children }) => (
  <>
    <Helmet />
    <SubMenu />
    <NavBar />
    <Nosotros />
    <Footer />
  </>
);

export default Layout;
