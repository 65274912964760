import React from "react";

const Calidad = () => (
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-8">
        <p>
          Siempre has querido una empresa profesional, de garantía, que se
          divierta con su trabajo, que tenga un equipo de coordinadores,
          pedagogos y monitores responsable, vocacional y divertido, pues...
          tenerlo!
        </p>
      </div>
    </div>
    <h1 className="subtitle is-4 has-text-centered">
      {" "}
      ¡Con Aristas puedes tenerlo!
    </h1>
  </div>
);

export default Calidad;
