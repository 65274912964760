import React from "react";
import { IconContext } from "react-icons";

const Contenido = ({ data }) => (
  <IconContext.Provider value={{ className: "icon is-medium has-text-black" }}>
    <div className="container">
      <div className="columns">
        {data.map(item => (
          <div className="column" key={item.key}>
            <div className="card is-shadowlesss">
              <div className="card-content">
                <div className="subtitle has-text-centered">{item.icon}</div>
                <p className="subtitle">{item.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </IconContext.Provider>
);

export default Contenido;
