import React from "react";

const Hero = ({ texto, subtexto, className }) => (
  <div className={`hero ${className}`}>
    <div className="hero-body">
      <h1 className="title has-text-centered">{texto}</h1>
      {subtexto && <h1 className="subtitle has-text-centered">{subtexto}</h1>}
    </div>
  </div>
);

export default Hero;
