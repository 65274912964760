import React from "react";
import {
  FaVolleyballBall,
  FaMedal,
  FaMousePointer,
  FaRegEdit
} from "react-icons/fa";

const ballIcon = <FaVolleyballBall />;
const medalIcon = <FaMedal />;
const pointerIcon = <FaMousePointer />;
const editIcon = <FaRegEdit />;

const info = [
  {
    key: 0,
    icon: ballIcon,
    text:
      "La mayor oferta de actividades extraescolares: deporte, cocina, ciencia, idiomas, aula de madrugadores, actividades para padres, etc."
  },
  {
    key: 1,
    icon: medalIcon,
    text:
      "Los precios más económicos en actividades extraescolares. Aumentamos la calidad, el apoyo a la AMPA y la eficiencia en la gestión."
  },
  {
    key: 2,
    icon: pointerIcon,
    text:
      "Todos los recursos que puedas imaginar para la gestión de actividades extraescolares: App, acceso web, intranet de participantes, CRM, informes, listados, etc."
  },
  {
    key: 3,
    icon: editIcon,
    text: "Novedosos materiales y exclusivas programaciones para tu cole."
  }
];

export default info;
